<template>
    <div>

        <div class="right-menu shipping-right">

            <subHeader pageName="SHIPPING REGIONS" />

            <tutorialsAction />

            <div class="db_top">
                <div class="db-container">

                    <moduleStats/>

                    <!-- search block starts here -->
                    <moduleSearch :moduleName="'shipping'" :disable="tourIsActive" ref="module_search"/>
                    <!-- search block ends here -->

                    <!-- data table starts here -->
                    <div class="tableMainWrapBox" style="margin-top: 25px;position: relative;" id="preview-record">
                        <div class="tableScrollWrap" ref="shippingRegionsTable" style="min-height: 300px; max-height: 300px;" v-if="isDev()">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <label class="ctmTableCheckContainer">
                                                    <input 
                                                        type="checkbox"
                                                        @click="toggleIsCheckedAll()"
                                                        :checked="checkedItems.length != 0 && checkedItems.length == regionsList.length"
                                                        :disabled="tourIsActive"
                                                    >
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Region Name">Region Name</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Shipping Type">Shipping Type</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Active/Disable">Active/Disable</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Action">Action</p>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="regionsLoader">
                                    <tr v-for="i in 5" :key="i">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="20"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="250"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td style="position: relative;">
                                            <vue-skeleton-loader
                                                type="rect"
                                                :width="95"
                                                :height="20"
                                                animation="fade"
                                            />
                                        </td>
                                        <td>
                                            <vue-skeleton-loader
                                                type="rect"
                                                :width="102"
                                                :height="20"
                                                animation="fade"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr v-if="regionsList && regionsList.length == 0">
                                        <td colspan="5" style="text-align:center"><p class="mb-0">{{ shippingRegionsKeyword ? `No shipping regions found matching '${shippingRegionsKeyword}'` : 'No Shipping Regions Available' }}</p></td>
                                    </tr>
                                    <tr v-for="(region, index) in regionsList" :key="index">

                                        <!-- checkbox block starts here -->
                                        <td @click="previewRegion(region,index,'input',$event)">
                                            <div class="ctmCheckWrap" data-open="true">
                                                <label class="ctmTableCheckContainer" data-open="false" @click.prevent="checkedItemHandle(region.id)">
                                                    <input type="checkbox" v-model="checkedItems" :value="region.id" :disabled="tourIsActive" data-open="false">
                                                    <span class="checkmark" data-open="false"></span>
                                                </label>
                                            </div>
                                        </td>
                                        <!-- checkbox block ends here -->

                                        <!-- region name block starts here -->
                                        <td class="mainTableTitle" @click="previewRegion(region,index,'input',$event)">
                                            <div class="editFieldTitle" data-open="true" id="region-name-field">
                                                <input 
                                                    type="text" 
                                                    placeholder="Write a region name"
                                                    v-model="region.name" 
                                                    @focus="regionSelection(region)" 
                                                    @blur="handleOTGUpdate(region,index)" 
                                                    :disabled="tourIsActive"
                                                    data-open="false"
                                                >
                                            </div>
                                        </td>
                                        <!-- region name block ends here -->

                                        <!-- shipping type block starts here -->
                                        <td @click="previewRegion(region,index,'solid',$event)">
                                            <div class="tableBodyCtmTxt" data-open="true">
                                                <p data-open="true">{{ region.shipping.type }}</p>
                                            </div>
                                        </td>
                                        <!-- shipping type block ends here -->

                                        <!-- active/disabled block starts here-->
                                        <td @click="previewRegion(region,index,'input',$event)">
                                            <div class="actionSwitch" data-open="true">
                                                <label class="switch" data-open="false">
                                                    <input
                                                        type="checkbox"
                                                        :id="`customSwitch-p-${region.id}`"
                                                        :value="region.isActive"
                                                        v-model="region.isActive"
                                                        :disabled="tourIsActive"
                                                        data-open="false"
                                                    >
                                                    <span class="slider" data-open="false" @click.stop="updateRegionStatus(region,index)"></span>
                                                </label>
                                            </div>
                                        </td>
                                        <!-- active/disabled block ends here-->

                                        <!-- action block starts here -->
                                        <td @click="previewRegion(region,index,'input',$event)">
                                            <div class="ctmDataTableActionBtn" v-if="isDeletingItem(region.id) && deleteLoader" data-open="true">
                                                <loaderBtn />
                                            </div>
                                            <div class="ctmDataTableActionBtn" data-open="true" v-else>
                                                <button class="delFunction" @click.prevent="deleteRegionHandle(region,index)" :disabled="tourIsActive" data-open="false">
                                                    <i aria-hidden="true" class="fa fa-trash-o" data-open="false"></i>
                                                </button>
                                            </div>
                                        </td>
                                        <!-- action block ends here -->

                                    </tr>

                                    <!-- load more regions loader starts here-->
                                    <tr v-if="isPending">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="20"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="250"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td style="position: relative;">
                                            <vue-skeleton-loader
                                                type="rect"
                                                :width="95"
                                                :height="20"
                                                animation="fade"
                                            />
                                        </td>
                                        <td>
                                            <vue-skeleton-loader
                                                type="rect"
                                                :width="102"
                                                :height="20"
                                                animation="fade"
                                            />
                                        </td>
                                    </tr>
                                    <!-- load more regions loader ends here-->

                                </tbody>
                            </table>
                        </div>
                        <div class="tableScrollWrap" ref="shippingRegionsTable" v-else>
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <label class="ctmTableCheckContainer">
                                                    <input 
                                                        type="checkbox"
                                                        @click="toggleIsCheckedAll()"
                                                        :checked="checkedItems.length != 0 && checkedItems.length == regionsList.length"
                                                        :disabled="tourIsActive"
                                                    >
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Region Name">Region Name</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Shipping Type">Shipping Type</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Active/Disable">Active/Disable</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Action">Action</p>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="regionsLoader">
                                    <tr v-for="i in 5" :key="i">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="20"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="250"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td style="position: relative;">
                                            <vue-skeleton-loader
                                                type="rect"
                                                :width="95"
                                                :height="20"
                                                animation="fade"
                                            />
                                        </td>
                                        <td>
                                            <vue-skeleton-loader
                                                type="rect"
                                                :width="102"
                                                :height="20"
                                                animation="fade"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr v-if="regionsList && regionsList.length == 0">
                                        <td colspan="5" style="text-align:center">
                                            <p class="mb-0">
                                                {{ shippingRegionsKeyword ? `No shipping regions found matching '${shippingRegionsKeyword}'` : 'No Shipping Regions Available' }}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr v-for="(region, index) in regionsList" :key="index">

                                        <!-- checkbox block starts here -->
                                        <td @click="previewRegion(region,index,'input',$event)">
                                            <div class="ctmCheckWrap" data-open="true">
                                                <label class="ctmTableCheckContainer" data-open="false" @click.prevent="checkedItemHandle(region.id)">
                                                    <input type="checkbox" v-model="checkedItems" :value="region.id" :disabled="tourIsActive" data-open="false">
                                                    <span class="checkmark" data-open="false"></span>
                                                </label>
                                            </div>
                                        </td>
                                        <!-- checkbox block ends here -->

                                        <!-- region name block starts here -->
                                        <td class="mainTableTitle" @click="previewRegion(region,index,'input',$event)">
                                            <div class="editFieldTitle" data-open="true" id="region-name-field">
                                                <input 
                                                    type="text" 
                                                    placeholder="Write a region name"
                                                    v-model="region.name" 
                                                    @focus="regionSelection(region)" 
                                                    @blur="handleOTGUpdate(region,index)" 
                                                    :disabled="tourIsActive"
                                                    data-open="false"
                                                >
                                            </div>
                                        </td>
                                        <!-- region name block ends here -->

                                        <!-- shipping type block starts here -->
                                        <td @click="previewRegion(region,index,'solid',$event)">
                                            <div class="tableBodyCtmTxt" data-open="true">
                                                <p data-open="true">{{ region.shipping.type }}</p>
                                            </div>
                                        </td>
                                        <!-- shipping type block ends here -->

                                        <!-- active/disabled block starts here -->
                                        <td @click="previewRegion(region,index,'input',$event)">
                                            <div class="actionSwitch" data-open="true">
                                                <label class="switch" data-open="false">
                                                    <input
                                                        type="checkbox"
                                                        :id="`customSwitch-p-${region.id}`"
                                                        :value="region.isActive"
                                                        v-model="region.isActive"
                                                        :disabled="tourIsActive"
                                                        data-open="false"
                                                    >
                                                    <span class="slider" data-open="false" @click.stop="updateRegionStatus(region,index)"></span>
                                                </label>
                                            </div>
                                        </td>
                                        <!-- active/disabled block ends here -->

                                        <!-- action block starts here -->
                                        <td @click="previewRegion(region,index,'input',$event)">
                                            <div class="ctmDataTableActionBtn" v-if="isDeletingItem(region.id) && deleteLoader" data-open="true">
                                                <loaderBtn />
                                            </div>
                                            <div class="ctmDataTableActionBtn" data-open="true" v-else>
                                                <button class="delFunction" @click.prevent="deleteRegionHandle(region,index)" :disabled="tourIsActive" data-open="false">
                                                    <i aria-hidden="true" class="fa fa-trash-o" data-open="false"></i>
                                                </button>
                                            </div>
                                        </td>
                                        <!-- action block ends here -->

                                    </tr>

                                    <!-- load more regions loader starts here -->
                                    <tr v-if="isPending">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="20"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="250"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td style="position: relative;">
                                            <vue-skeleton-loader
                                                type="rect"
                                                :width="95"
                                                :height="20"
                                                animation="fade"
                                            />
                                        </td>
                                        <td>
                                            <vue-skeleton-loader
                                                type="rect"
                                                :width="102"
                                                :height="20"
                                                animation="fade"
                                            />
                                        </td>
                                    </tr>
                                    <!-- load more regions loader ends here -->

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- data table starts here -->

                    <!--bottom action bar starts here -->
                    <div class="row product-bottom-bar m-0" v-if="checkedItems.length > 1">
                        <div class="col-md-6 model-footer-main1">
                            <div class="bottom-bar">
                                <a href="" class="model-footer-1 mr-5" @click.prevent="toggleIsCheckedAll()" v-if="checkedItems.length != regionsList.length">
                                    <i class="fa fa-check-square-o" aria-hidden="true"></i>
                                    <p>SELECT ALL</p>
                                </a>
                                <a href="" class="model-footer-1" @click.prevent="clearSelection()">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                        <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                                    </svg>
                                    <p>CLEAR SELECTION</p>
                                </a>
                            </div>
                            <div class="model-footer-2">{{ checkedItems.length }}</div>
                        </div>
                        <div class="col-md-6 model-footer-main1 product-row-reverse">
                            <div class="bottom-bar">
                                <a href="" class="model-footer-1 mr-4" @click.prevent="regionsBulkActionHandle('active')">
                                    <p>ACTIVE</p>
                                </a>
                                <a href="" class="model-footer-1 mr-4" @click.prevent="regionsBulkActionHandle('disable')">
                                    <p>DISABLE</p>
                                </a>
                                <a href="" class="model-footer-1 ml-4" @click.prevent="regionsBulkActionHandle('delete')">
                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                    <p>DELETE</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!--bottom action bar ends here -->

                </div>
            </div>

            <!-- shipping partner section starts here -->
            <div class="paymentSection" v-if="isDev()">
                <div class="db-container">
                    <div class="paymentMainBlock">

                        <!-- fedEx shipping partner starts here -->
                        <div class="paymentMethodWrap" id='fedex-step'>
                            <div class="row align-items-center">
                                <div class="col-md-6">
                                    <div class="paymentHeading">
                                        <h4>FedEx</h4>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="actionSwitch">
                                        <label class="switch" id="fedex-step-status">
                                            <input 
                                                type="checkbox"
                                                v-model="fedExShippingPartner.isActive" 
                                                :disabled="tourIsActive"
                                                @change="toggleProceedableHandle('fedEx')"
                                            >
                                            <span class="slider"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <ValidationObserver ref="fedExObserver">
                                <div class="bankingFormField" :class="fedExShippingPartner.isActive ? 'expandForm' : ''" id="fedex-form">
                                    <form @submit.prevent="validate('fedEx')">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <ValidationProvider name="API key" rules="required" v-slot="{ errors }">
                                                    <div class="bankInputField">
                                                        <label for="fedex-api-key">API Key <span>*</span></label>
                                                        <input 
                                                            type="text" 
                                                            id="fedex-api-key"
                                                            placeholder="API key"
                                                            v-model="fedExShippingPartnerData.api_key" 
                                                            :disabled="tourIsActive"
                                                        >
                                                        <span class="text-danger" >{{ errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-md-6">
                                                <ValidationProvider name="secret key" rules="required" v-slot="{ errors }">
                                                    <div class="bankInputField">
                                                        <label for="fedex-secret-key">Secret Key <span>*</span></label>
                                                        <input 
                                                            type="text" 
                                                            id="fedex-secret-key"
                                                            placeholder="Secret key"
                                                            v-model="fedExShippingPartnerData.secret_key" 
                                                            :disabled="tourIsActive"
                                                        >
                                                        <span class="text-danger" >{{ errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="paymentSaveBtn">
                                                    <loaderBtn v-if="fedExLoader"/>
                                                    <Button id="fedex-save-btn" :disabled="tourIsActive" v-else>Save</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </ValidationObserver>
                        </div>
                        <!-- fedEx shipping partner ends here -->

                    </div>
                </div>
            </div>
            <!-- shipping partner section ends here -->

        </div>

        <!--vue tour-->
        <v-tour name="allShippingsTour" :steps="tourStepsDev" :options="tourOptions" :callbacks="tourCallbacks" v-if="isDev()"></v-tour>
        <v-tour name="allShippingsTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks" v-else></v-tour>

    </div>
</template>

<script>
import subHeader from "@/components/subHeader.vue";
import moduleStats from "@/components/moduleStats.vue";
import tutorialsAction from "@/components/tutorialsAction.vue";
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { MessageBox, Message, Loading } from 'element-ui'
import loaderBtn from "@/components/loaderBtn.vue";
import moduleSearch from "@/components/ModuleSearch/index.vue";

export default {
    metaInfo() {
        return {
            title: 'E-Commerce | Shipping Regions | YeetCommerce',
        };
    },
    data(){
        return{

            //shipping start
            regionsList: [],
            regionsLoader: false,
            tempRegion: null,
            //shipping end

            //checked items start
            checkedItems: [],
            //checked items end

            //deleting start
            deleteLoader: false,
            deletingItems: [],
            //deleting end

            //tour start
            tourOptions: {
                useKeyboardNavigation: false,
                labels: {
                    buttonSkip: 'Skip Tour',
                    buttonPrevious: 'Previous',
                    buttonNext: 'Next',
                    buttonStop: 'Finish Tour'
                }
            },
            tourCallbacks: {
                onPreviousStep: this.handlePreviousStep,
                onNextStep: this.handleNextStep,
                // onStart: this.handleTourStart,
                onStop: this.handleTourStop,
                onFinish: this.handleTourFinish,
                onSkip: this.handleTourSkip
            },
            tourSteps:[
                //header steps
                {
                    target: '#tour-start-btn',
                    content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#master-search',
                    content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    },
                },
                {
                    target: '#add-new-dropDown',
                    content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#help-link',
                    content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#user-options-dropdown',
                    content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                //shipping regions
                {
                    target: '#module-search',
                    content: `<strong>Search Shipping Region</strong><br>It enables you to search specific shipping region.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#preview-record',
                    content: `<strong>Regions Table</strong><br>You can preview and edit the added shipping regions by clicking on each individual record present in the table.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'top'
                    }
                },
                {
                    target: '#add-new-record',
                    content: `Add a <strong>Shipping Region</strong><br>By clicking this button, you can add a new shipping region and open a screen to input and save the details.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
            ],
            tourStepsDev:[
                //header steps
                {
                    target: '#tour-start-btn',
                    content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#master-search',
                    content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    },
                },
                {
                    target: '#add-new-dropDown',
                    content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#help-link',
                    content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#user-options-dropdown',
                    content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                //shipping regions
                {
                    target: '#module-search',
                    content: `<strong>Search Shipping Region</strong><br>It enables you to search specific shipping region.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#preview-record',
                    content: `<strong>Regions Table</strong><br>You can preview and edit the added shipping regions by clicking on each individual record present in the table.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'top'
                    }
                },
                {
                    target: '#add-new-record',
                    content: `Add a <strong>Shipping Region</strong><br>By clicking this button, you can add a new shipping region and open a screen to input and save the details.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                //shipping partner steps
                {
                    target: '#fedex-step',
                    content: `<strong>FedEx Shipping Partner</strong><br>FedEx is available for the user as a shipping partner.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#fedex-step-status',
                    content: `<strong>Active/Disabled</strong><br>Status of the FedEx shipping partner can be changed using this option.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#fedex-form',
                    content: `<strong>FedEx Shipping Partner</strong><br>FedEx shipping partner can be enabled by filling the information in the available form.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#fedex-api-key',
                    content: `<strong>FedEx API Key</strong><br>FedEx API key can be added in this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#fedex-secret-key',
                    content: `<strong>FedEx Secret Key</strong><br>FedEx secret key can be added in this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#fedex-save-btn',
                    content: `<strong>Save Details</strong><br>FedEx shipping partner details can be saved by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
            ],
            tourIsActive: false,
            //tour end

            //shipping partner start
            fedExLoader: false,
            fedExShippingPartner: [],
            tempFedExShippingPartner: [],
            fedExShippingPartnerData: {
                api_key: '',
                secret_key: '',
            },
            //shipping partner end

        }
    },
    components:{
        subHeader,
        tutorialsAction,
        moduleStats,
        loaderBtn,
        moduleSearch,
    },
    computed:{
        ...mapGetters({
            settings: "settings_module/settings",
            storeRegions: "shipping_module/regions",
            shippingRegionsKeyword: 'shipping_module/keyword',
            storeRegionsCurrentPage: "shipping_module/currentPage",
            storeRegionsLastPage: "shipping_module/lastPage",
            isPending: "shipping_module/isPending",
        }),
    },
    watch:{
        storeRegions: {
            handler: function(val) {

                this.regionsLoader = false;
                this.regionsList = val;

            },deep: true,
        },
    },
    methods:{
        //infinite scroll start
        handleScrollEvent(event){

            let element = this.$refs.shippingRegionsTable;

            let maxScrollTop = element.scrollHeight - element.clientHeight;

            if(element.scrollTop >= maxScrollTop - 100){

                if(this.isPending == false && this.storeRegionsCurrentPage != this.storeRegionsLastPage){

                    this.$store.dispatch("shipping_module/fetchStoreRegionsRequest",{ size: 12, page: this.storeRegionsCurrentPage + 1, loadData: 'more'});

                }

            }

        },
        //infinite scroll end

        //OTG update methods start
        regionSelection(listedRegion){

            this.tempRegion = _.cloneDeep(listedRegion);

        },
        async handleOTGUpdate(region,index){

            if(this.tempRegion.name != region.name){

                if(region.name.trim().length == 0){

                    this.$notify({
                        type: 'warning',
                        title: 'Alert',
                        message: "The region name field is required.",
                    });

                    this.regionsList[index].name = this.tempRegion.name;

                    return

                }

                if(region.name.trim().length > 60){

                    this.$notify({
                        type: 'warning',
                        title: 'Alert',
                        message: "The region name field may not be greater than 60 characters.",
                    });

                    this.regionsList[index].name = this.tempRegion.name;

                    return

                }

                MessageBox.confirm(
                    'You have unsaved changes, save and proceed?',
                    'Confirm',
                    {
                        distinguishCancelAndClose: true,
                        confirmButtonText: 'Save',
                        cancelButtonText: 'Discard Changes',
                    }
                ).then(async () => {

                    let loader = Loading.service({
                        text: "The shipping region is being updated. Please wait!",
                        fullscreen: true,
                    });

                    //updating the region 
                    let formData = new FormData();

                    formData.append('region_id',region.id);
                    formData.append('field','name');

                    formData.append('region_name',region.name.trim());

                    try{
                        let res = await this.$axios.post('region/update/selective',formData);
                        if(res.data.status_code == '1014'){

                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: res.data.message,
                            });

                            let payload = {
                                field: 'name',
                                regionIndex: index,
                                regionName: res.data.region.name
                            }

                            this.$store.commit('shipping_module/selective_region_update',payload);

                        }
                    }catch(error){

                        this.regionsList[index].name = this.tempRegion.name;

                        this.tempRegion = null;

                        if(error.response){

                            if(error.response.data.error.region_id){

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: error.response.data.error.region_id[0],
                                });

                            }else if(error.response.data.error.includes('already exists')){

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: error.response.data.error,
                                });

                            }else{

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: error.response.data.message,
                                });

                            }

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.message,
                            });

                        }

                    }finally{

                        loader.close();

                    }

                }).catch(()=>{

                    this.regionsList[index].name = this.tempRegion.name;

                    this.$notify({
                        type: "info",
                        title: "Changes Discarded",
                        message: "Changes discarded successfully.",
                    });

                    this.tempRegion = null;

                });

            }

        },
        //OTG update methods end

        //preview region start
        previewRegion(listedRegion,i,field,e){

            if(!this.tourIsActive){

                if(field == 'input'){
            
                    if((e.target.tagName == "TD" || e.target.dataset.open == "true") && !this.tourIsActive){
                        
                        MessageBox.confirm(
                            "Do you really want to explore the details of the shipping region?", 
                            "Warning", 
                            {
                                confirmButtonText: "Yes",
                                cancelButtonText: "Cancel",
                                type: "warning",
                            }
                        ).then(async () => {

                            this.$router.push({path: `/e-commerce/shipping-region/edit/${listedRegion.id}`, params: { region: listedRegion },});
                            
                        }).catch(() => {})

                    }
                    
                }else{

                    MessageBox.confirm(
                        "Do you really want to explore the details of the shipping region?", 
                        "Warning", 
                        {
                            confirmButtonText: "Yes",
                            cancelButtonText: "Cancel",
                            type: "warning",
                        }
                    ).then(async () => {

                        this.$router.push({path: `/e-commerce/shipping-region/edit/${listedRegion.id}`, params: { region: listedRegion },});

                    }).catch(() => {})

                }

            }

        },
        //preview region end

        //region status & deletion start
        async updateRegionStatus(listedRegion,i){

            if(!this.tourIsActive){

                let loader = Loading.service({
                    text: "The shipping region is undergoing status update. Please wait!",
                    fullscreen: true,
                });

                let formData = new FormData();

                formData.append("region_id", listedRegion.id);
                formData.append("isActive", listedRegion.isActive ? 0 : 1);
            
                try{
                    let res = await this.$axios.post("/changeRegionStatus", formData);
                    if(res.data.status_code == "10141"){

                        this.$notify({
                            type: "success",
                            title: "Success",
                            message: res.data.message,
                        });

                        let payload = {
                            index: i,
                            updatedRegion: res.data.region,
                        };

                        this.$store.commit("shipping_module/update_regions_status", payload);

                    }
                }catch(error){

                    listedRegion.isActive = !listedRegion.isActive;

                    if(error.response){

                        if(error.response.data.error.region_id){
                            
                            this.$message({
                                type: "error",
                                showClose: true,
                                message: error.response.data.error.region_id[0],
                            });

                        }else if(error.response.data.error.includes("deleted or isn't available")){

                            this.$message({
                                type: "error",
                                showClose: true,
                                message: error.response.data.error,
                            });

                        }else{

                            this.$message({
                                type: "error",
                                showClose: true,
                                message: error.response.data.message,
                            });

                        }

                    }else{

                        this.$message({
                            type: "error",
                            showClose: true,
                            message: error.message,
                        });

                    }

                }finally{

                    loader.close();

                }

            }

        },
        async deleteRegionHandle(listedRegion, i){
            
            if(!this.tourIsActive){
            
                MessageBox.confirm(
                    "Are you sure you want to delete the shipping region?.", 
                    "Warning", 
                    {
                        confirmButtonText: "Yes",
                        cancelButtonText: "Cancel",
                        type: "warning",
                    }
                ).then(async () => {

                    let loader = Loading.service({
                        text: "The shipping region is being removed. Please wait!",
                        fullscreen: true,
                    });

                    let formData = new FormData();

                    formData.append("id", listedRegion.id);

                    this.deletingItems.push(listedRegion.id);
                    this.deleteLoader = true

                    try{
                        let res = await this.$axios.delete(`shipping/region/remove/${listedRegion.id}`);
                        if(res.data.status_code == "1165"){

                            this.$notify({
                                type: "success",
                                title: "Success",
                                message: res.data.message,
                            });

                            // this.$store.commit("shipping_module/delete_regions_list", i);
                            
                            //removing deleted item from checked items list
                            this.checkedItems = this.checkedItems.filter((item) => {

                                if(item != listedRegion.id){

                                    return item;

                                }

                            });

                            this.regionsLoader = true;

                            this.$store.dispatch("shipping_module/fetchStoreRegionsRequest",{ size: 12, page: 1, loadData: 'new'});

                        }
                    }catch(error){

                        if(error.response){

                            if(error.response.data.error.id){

                                this.$message({
                                    type: "error",
                                    showClose: true,
                                    message: error.response.data.error.id[0],
                                }); 

                            }else{

                                this.$message({
                                    type: "error",
                                    showClose: true,
                                    message: error.response.data.message,
                                });  

                            }

                        }else{

                            this.$message({
                                type: "error",
                                showClose: true,
                                message: error.message,
                            });

                        }

                    }finally{

                        loader.close();

                        this.deleteLoader = false;
                        this.deletingItems = [];

                    }

                }).catch(() =>{})

            }

        },
        //region status & deletion end

        //region bulk action start
        regionsBulkActionHandle(action){

            MessageBox.confirm(
                'Do you really want to make the changes?',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }
            ).then(async ()=>{

                if(action == 'active' || action == 'disable'){

                    let loader = Loading.service({
                        text: "The selected shipping regions are undergoing status update. Please wait!",
                        fullscreen: true,
                    });

                    let formData = new FormData();

                    formData.append('resource_type', 'region');
                    formData.append("resource_ids", JSON.stringify(this.checkedItems));
                    formData.append('action', action);

                    try{
                        let res = await this.$axios.post('/bulk-action',formData);
                        if(res.data.status_code == '2031'){

                            this.$notify({
                                type: "success",
                                title: "Success",
                                message: "Regions status has been successfully changed.",
                            });

                            let payload = {
                                action: 'statusChange',
                                status: action == "active" ? 1 : 0,
                                listIds: this.checkedItems
                            }

                            this.$store.commit("shipping_module/brands_bulk_action", payload);

                            this.checkedItems = [];

                        }
                    }catch(error){

                        if(error.response){

                            if(error.response.data.message){

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: error.response.data.message,
                                });

                            }else{

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: "One or more of the selected shipping regions may have been deleted or didn't available.",
                                });

                            }

                        }else{

                            this.$message({
                                type: "error",
                                showClose: true,
                                message: error.message,
                            });

                        }

                    }finally{

                        loader.close();

                    }

                }else if(action == 'delete'){
                    
                    let loader = Loading.service({
                        text: "The selected shipping regions are being removed. Please wait!",
                        fullscreen: true,
                    });

                    let formData = new FormData();

                    formData.append('resource_type', 'region');
                    formData.append("resource_ids", JSON.stringify(this.checkedItems));
                    formData.append('action', action);

                    this.deletingItems = _.cloneDeep(this.checkedItems);
                    this.deleteLoader = true;

                    try{
                        let res = await this.$axios.post('/bulk-action',formData);
                        if(res.data.status_code == '2031'){
                        
                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: "Regions have been successfully removed.",
                            });

                            // let payload = {
                            //     action: 'delete',
                            //     listIds: this.checkedItems
                            // }

                            // this.$store.commit("shipping_module/brands_bulk_action", payload);

                            this.regionsLoader = true;

                            this.$store.dispatch("shipping_module/fetchStoreRegionsRequest",{ size: 12, page: 1, loadData: 'new'});

                            this.checkedItems = [];

                        }
                    }catch(error){

                        if(error.response){

                            if(error.response.data.message){

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: error.response.data.message,
                                });

                            }else{

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: "One or more of the selected shipping regions may have been deleted or didn't available.",
                                });

                            }

                        }else{

                            this.$message({
                                type: "error",
                                showClose: true,
                                message: error.message,
                            });

                        }

                    }finally{

                        loader.close();

                        this.deletingItems = [];
                        this.deleteLoader = false;

                    }

                }

            }).catch(() => {})

        },
        //region bulk action end

        //other methods start
        isDeletingItem(id){

            let find = this.deletingItems.find(x => x == id);

            if(find){

                return true;

            }else{

                return false;

            }

        },
        toggleIsCheckedAll(){

            if(!this.tourIsActive){

                if((this.checkedItems.length == this.regionsList.length)){

                    this.checkedItems = [];

                }else if(this.checkedItems.length < this.regionsList.length){

                    this.checkedItems = [];
                    
                    this.regionsList.forEach(region => {

                        this.checkedItems.push(region.id);

                    });

                }

            }

        },
        checkedItemHandle(regionID){

            if(!this.tourIsActive){

                let index = this.checkedItems.findIndex(item => item == regionID);

                if(index != -1){

                    this.checkedItems.splice(index,1);

                }else{

                    this.checkedItems.push(regionID);

                }

            }

        },
        clearSelection(){

            MessageBox.confirm(
                'Do you really want to uncheck the selected records?',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }
            ).then(async () => {

                this.checkedItems = [];

            }).catch(() => {})

        },
        //other methods end

        //search region start
        searchShippingRegion(keyword){

            this.$refs.module_search.searchedKeyword = keyword

            this.$refs.module_search.search();

        },
        //search region end
        
        //tour methods start
        startShippingTour(){

            this.$tours.allShippingsTour.start();

            this.tourIsActive = true;
            
        },
        handleNextStep(currentStep){

            if(currentStep + 1 == 10){

                this.fedExShippingPartner.isActive = true;

            }

        },
        handlePreviousStep(currentStep){

            if(currentStep == 10){

                this.fedExShippingPartner.isActive = false;

            }

        },
        handleTourStop(){
            
            if(this.isDev()){

                this.loadShippingPartner();

            }

            this.tourIsActive = false;

        },
        handleTourFinish(){

            if(this.isDev()){

                this.loadShippingPartner();

            }

            this.tourIsActive = false;

        },
        handleTourSkip(){

            if(this.isDev()){

                this.loadShippingPartner();

            }

            this.tourIsActive = false;

        },
        //tour methods end

        //shipping parnter methods start
        toggleProceedableHandle(form){

            if(form == 'fedEx'){

                if(this.fedExShippingPartner.isActive){

                    this.$refs.fedExObserver.validate().then((success) => {

                        if(!success){

                            const errors = Object.entries(this.$refs.fedExObserver.errors)
                            .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

                            this.$refs.fedExObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center",});

                            this.$notify({
                                type: 'info',
                                title: 'Information Missing',
                                message: "Kindly enter required information in the shipping partner and save it to enable it.",
                            });

                            this.$refs.fedExObserver.reset();

                        }else{

                            if(this.fedExShippingPartner.isActive != this.tempFedExShippingPartner.isActive){

                                this.shippingPartnerStatusToggle(form);

                            }

                        }

                    });

                }else{

                    if(this.fedExShippingPartner.isActive != this.tempFedExShippingPartner.isActive){

                        this.shippingPartnerStatusToggle(form);

                    }

                }

            }

        },
        async shippingPartnerStatusToggle(form){

            let formData = new FormData();

            if(form == 'fedEx'){

                formData.append('id',this.fedExShippingPartner.id);
                formData.append('isActive',this.fedExShippingPartner.isActive ? 1 : 0);

            }

            let loader = Loading.service({
                text: "The shipping partner is undergoing status update. Please wait!",
                fullscreen: true,
            });

            try{
                let res = await this.$axios.post('shipping/partner/toggle',formData);
                if(res.data.status_code == '1600' || res.data.status_code == '1601'){

                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: 'Shipping partner has been '+ res.data.message,
                    });

                }
            }catch(error){

                if(error.response){

                    if(error.response.data.error.includes('At least one')){
                        
                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                loader.close();

                this.loadShippingPartner();

            }

        },

        async validate(form){

            if(form == 'fedEx'){

                this.$refs.fedExObserver.validate().then((success) => {
                    
                    if(!success){

                        const errors = Object.entries(this.$refs.fedExObserver.errors)
                        .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

                        this.$refs.fedExObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

                    }else{

                        this.updateShippingPartner(form);
                        
                    }

                });

            }

        },
        async updateShippingPartner(form){

            let formData = new FormData();

            if(form == 'fedEx'){

                formData.append('id', this.fedExShippingPartner.id);
                formData.append('isActive', this.fedExShippingPartner.isActive ? 1 : 0);

                //data
                formData.append('api_key', this.fedExShippingPartnerData.api_key);
                formData.append('secret_key', this.fedExShippingPartnerData.secret_key);

                this.fedExLoader = true;

            }

            let loader = Loading.service({
                text: "The shipping partner is being updated. Please wait!",
                fullscreen: true,
            });

            try{
                let res = await this.$axios.post('/shipping/partner/update',formData);
                if(res.data.status_code == "1772"){

                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });

                }
            }catch(error){

                if(error.response){
                
                    if(error.response.data.error.includes('while authenticating') || 
                    error.response.data.error.includes('Postal Code Validation API')){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }
                
                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                loader.close();

                this.fedExLoader = false;

                this.loadShippingPartner();

            }

        },

        async loadShippingPartner(){

            let loader = Loading.service({
                text: "Fetching shipping partners. Please wait!",
                fullscreen: true,
            });

            try{
                let res = await this.$axios.get('/shipping/partner/all');
                if(res.data.status_code == "1771"){

                    res.data.shipping_partners.forEach((shippingPartner) => {

                        if(shippingPartner.name == 'FedEx'){

                            this.fedExShippingPartner = shippingPartner;
                            this.tempFedExShippingPartner = _.cloneDeep(shippingPartner);

                            let partner = JSON.parse(this.fedExShippingPartner.data);

                            this.fedExShippingPartnerData = partner;

                        } 

                    });

                }
            }catch(error){

                this.$message({
                    type: 'error',
                    showClose: true,
                    message: error.response ? error.response.data.message : error.message,
                });

            }finally{

                loader.close();

            }

        },
        //shipping parnter methods end

        //dev environment check
        isDev(){

            if(process.env.VUE_APP_ENV == 'dev'){

                return true;

            }else{

                return false;

            }

        },

    },
    mounted(){

        this.$refs.shippingRegionsTable.addEventListener('scroll', this.handleScrollEvent);

        if(this.$route.query.search){
      
            this.$refs.module_search.searchedKeyword = this.$route.query.search

            this.$router.replace({'query': null});

            this.$refs.module_search.search();

        }

    },
    beforeMount(){

        this.regionsLoader = true;
        try{

            if((!this.storeRegions || this.storeRegions.length == 0) && this.shippingRegionsKeyword.length == 0){

                this.$store.dispatch("shipping_module/fetchStoreRegionsRequest",{ size: 12, page: 1, loadData: 'new'});

            }else{

                this.regionsList = this.storeRegions;
                this.regionsLoader = false;

            }

            if(this.isDev()){

                //loading shipping partner
                this.loadShippingPartner();

            }

        }catch(error){

            this.regionsLoader = false;

            this.$message({
                type: "error",
                showClose: true,
                message: error.response ? error.response.data.message : error.message,
            });

        }

    },
    beforeDestroy(){

    },
}
</script>

<style>

</style>